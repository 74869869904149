<template>
  <v-main v-bind:style="{ 'background-color': promotion.color }">
    <v-container fill-height fluid>
      <v-row justify="center">
        <v-col cols="12">
          <v-card v-if="loading" flat class="text-center transparent">
            <v-progress-circular
              indeterminate
              color="primary"
              :size="50"
            ></v-progress-circular>
          </v-card>

          <v-card
            v-else
            v-bind:style="{ 'background-color': promotion.color }"
            class="mx-auto"
            max-width="400"
            :flat="!promotion.isValid || submitted"
          >
            <template v-if="promotion.isValid">
              <template v-if="!submitted">
                <v-img v-if="promotion.image" :src="promotion.image"></v-img>
                <v-card-title
                  v-else
                  class="headline text-center grey--text text--darken-3"
                  style="display: block"
                >
                  {{ promotion.name }}
                </v-card-title>
              </template>

              <v-card-subtitle
                v-if="!submitted"
                class="caption text-center grey--text text--darken-2"
              >
                Fill the details below and click submit.
              </v-card-subtitle>

              <v-card-text
                class="px-5 pb-5 text-center grey--text text--darken-3"
              >
                <v-form v-if="!submitted" ref="form">
                  <label class="overline">Name</label>
                  <v-text-field
                    v-model="model.name"
                    dense
                    hide-details
                    outlined
                    rounded
                    class="centered-input grey lighten-4 mb-2"
                    :rules="[(v) => !!v || '']"
                    @keyup="hasError = false"
                  ></v-text-field>

                  <label class="overline">Identity card no</label>
                  <v-text-field
                    v-model="model.idCardNo"
                    dense
                    hide-details
                    outlined
                    rounded
                    class="centered-input grey lighten-4 mb-2"
                    :rules="[(v) => !!v || '']"
                    @keyup="hasError = false"
                  ></v-text-field>

                  <label class="overline">Contact no</label>
                  <v-text-field
                    v-model="model.mobile"
                    dense
                    hide-details
                    outlined
                    rounded
                    class="centered-input grey lighten-4 mb-2"
                    type="number"
                    :rules="[(v) => !!v || '']"
                    @keyup="hasError = false"
                  ></v-text-field>

                  <label class="overline">Coupon code</label>
                  <v-text-field
                    v-model="model.code"
                    dense
                    hide-details
                    outlined
                    rounded
                    class="centered-input grey lighten-4 mb-2"
                    :rules="[(v) => !!v || '']"
                    @input="(val) => (model.code = model.code.toUpperCase())"
                    @keyup="hasError = false"
                  ></v-text-field>

                  <v-expand-transition>
                    <v-alert
                      v-if="hasError"
                      class="my-6 rounded-pill white--text"
                      color="error lighten-1"
                    >
                      {{ errorMessage }}
                    </v-alert>
                  </v-expand-transition>

                  <v-btn
                    large
                    block
                    depressed
                    rounded
                    color="primary"
                    :class="hasError ? 'mt-6' : 'mt-8'"
                    :loading="submitting"
                    @click="submit"
                  >
                    Submit
                  </v-btn>
                </v-form>

                <v-img
                  v-if="submitted && response.prize"
                  contain
                  class="mx-auto"
                  :src="response.prize"
                ></v-img>

                <v-expand-transition>
                  <v-alert
                    v-if="submitted && !response.prize"
                    prominent
                    class="mb-0 rounded-pill white--text"
                    color="success lighten-1"
                    type="success"
                  >
                    Submitted. Thank you!
                  </v-alert>
                </v-expand-transition>
              </v-card-text>
            </template>

            <v-card-text
              v-else
              class="headline text-center grey--text text--darken-3"
            >
              Invalid promotion code or the promotion has ended!
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import images from './images';

export default {
  name: 'ResponseForm',

  data: () => ({
    errorMessage: null,
    hasError: false,
    loading: true,
    model: {
      code: null,
    },
    promotion: {
      color: '#CFD8DC',
    },
    response: {},
    submitting: false,
    submitted: false,
  }),

  async created() {
    const code = this.$route.params.promotionCode;

    this.model.code = this.$route.query.code
      ? this.$route.query.code.toUpperCase()
      : null;

    this.$axios({
      url: `promo/${code}/details`,
      baseURL: '/',
    })
      .then((response) => {
        this.promotion = Object.assign({}, this.promotion, response.data);

        const promotionColors = { 
          JAFT: '#F8AE42',
          MUex22: '#006cab',
          '3Y-muX': '#C2E5F8'
        };
        this.promotion.image = images[this.promotion.code];
        if (promotionColors[this.promotion.code]) {
          this.promotion.color = promotionColors[this.promotion.code];
        }

        this.promotion.isValid = true;
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.loading = false;
      });
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      this.submitting = true;
      await this.$axios({
        method: 'post',
        url: 'response',
        baseURL: '/',
        data: this.model,
      })
        .then((response) => {
          this.response = response.data;
          this.submitted = true;
        })
        .catch((err) => {
          if (err && err.response.status === 422) {
            this.errorMessage = 'Invalid coupon code!';
          } else {
            this.errorMessage = 'There was an error. Please try again!';
          }
          this.hasError = true;
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>
